import React from 'react';
import {
    TextField
} from 'react-admin';
import CustomTableList from './CustomTableList';

class CategoryList extends CustomTableList {


    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'name') {
            fieldList.push(<TextField label="Name (english)" source="name.en"/>);
            fieldList.push(<TextField label="Name (中文)" source="name.zh"/>);
        }
        return false;
    }
}

export default CategoryList;