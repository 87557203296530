import React from 'react';


import CustomTableCreate from './CustomTableCreate';
import MyInputFile from '../fields/MyInputFile';

class ClubCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'logo') {
            fieldList.push(<MyInputFile source="logo" label="Logo" folder="club-logo" />);
            return true;
        } else if (fieldName === 'picture') {
            fieldList.push(<MyInputFile source="picture" label="Picture" folder="club-picture" />);
            return true;
        }

        return false;
    }
}

export default ClubCreate;