import React from 'react';
import {
    TextInput
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';


class CategoryEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'name') {
            fieldList.push(<TextInput label="Name (english)" source="name.en"/>);
            fieldList.push(<TextInput label="Name (中文)" source="name.zh"/>);
            return true;
        }
        return false;
    }
}

export default CategoryEdit;