import React from 'react';
import Typography from '@material-ui/core/Typography';

const ElipsizeTextField = ({ record, field, maxLength }) => {

    if (!maxLength) {
        maxLength = 40;
    }

    let str = record[field];
    if (field.includes('.')) {
        const subObjects = field.split('.');
        str = record;
        for (const i in subObjects) {
            if (str && str[subObjects[i]]) {
                str = str[subObjects[i]];
            }
        }
    }
    return str && typeof str === 'string' ?
        <span>{str.length > maxLength ? `${str.slice(0, maxLength)}...` : str}</span>
        : null;
};

ElipsizeTextField.defaultProps = {
    addLabel: true
};

ElipsizeTextField.propTypes = {
    // @ts-ignore
    ...Typography.propTypes
};

export default ElipsizeTextField;