import React from 'react';
import {
    TextInput
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';

class CityCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'name') {

            fieldList.push(<TextInput label="Name (english)" source="name.en"/>);
            fieldList.push(<TextInput label="Name (中文)" source="name.zh"/>);

            return true;
        }
        return false;
    }
}

export default CityCreate;