import { createMuiTheme } from '@material-ui/core/styles';

const SinopadelTheme = createMuiTheme({
    typography: {
        // Use the system font instead of the default Roboto font.
        // fontFamily: 'ATCOverlook-BoldItalic',
    }
    // overrides: {
    //     MuiButton: { // override the styles of all instances of this component
    //         root: { // Name of the rule
    //             color: 'white', // Some CSS
    //         },
    //     },
    // },
});

export default SinopadelTheme;