export default {

    getPlural: (sigularWord) => {
        const lastChar = sigularWord.substr(sigularWord.length - 1);

        if (lastChar === 'y') {
            return `${sigularWord.substring(0, sigularWord.length - 1)}ies`;
        }
        return `${sigularWord}s`;
    },

    displayFieldForPlural: (plural) => {
        let optionText = 'name';

        if (plural === 'videos' || plural === 'series') {
            optionText = 'title';
        } else if (plural === 'clubs') {
            optionText = 'nickname';
        } else if (plural === 'cities') {
            optionText = 'name.en';
        } else if (plural === 'categories') {
            optionText = 'name.en';
        }
        console.log(`displayFieldForPlural ${plural} : return ${optionText}`);

        return optionText;
    }
};