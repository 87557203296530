import React from 'react';
import {
    // FunctionField,
    ChipField
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles({
    viewed: { backgroundColor: '#00000000' },
    new: {
        backgroundColor: 'crimson',
        color: 'white'
    }
});

const NewOrderField = (props) => {
    const classes = useStyles();

    console.log(props.record);
    console.log('viewed');
    console.log(props.record.viewed);
    return (
        <ChipField
            className={classnames({
                [classes.viewed]: props.record.viewed,
                [classes.new]: !props.record.viewed
            })}
            record={{ viewed: `${props.record.viewed ? '' : 'new' }` }}
            source="viewed"
            // {...props}
        />
    );
};

export default NewOrderField;