import React from 'react';
import {
    TextField,
    FunctionField
} from 'react-admin';
import CustomTableShow from './CustomTableShow';
import ElipsizeTextField from '../fields/ElipsizeTextField';


class ProductShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'paymentMethod') {
            return true;
        } else if (fieldName === 'name') {
            fieldList.push(<TextField label="Name (english)" source="name.en"/>);
            fieldList.push(<TextField label="Name (中文)" source="name.zh"/>);
            return true;
        } else if (fieldName === 'description') {
            fieldList.push(<ElipsizeTextField label="Description (english)" source="description" field="description.en" maxLength={10000}/>);
            fieldList.push(<ElipsizeTextField label="Description (中文)" source="description" field="description.zh" maxLength={10000}/>);
        } else if (fieldName === 'yuanPayment') {
            fieldList.push(<FunctionField label="Price 1" render={(product) => `${product.yuanPayment ? `${product.yuanPayment.yuan ? product.yuanPayment.yuan : '-'} ¥` : ''}` } />);
        } else if (fieldName === 'bothPayment') {
            fieldList.push(<FunctionField label="Price 2" render={(product) => `${product.bothPayment ? `${product.bothPayment.yuan ? product.bothPayment.yuan : '-'} ¥ + ${product.bothPayment.sployalty ? product.bothPayment.sployalty : '-'} SPL` : ''}` } />);
        } else if (fieldName === 'sployaltyPayment') {
            fieldList.push(<FunctionField label="Price 3" render={(product) => `${product.sployaltyPayment ? `${product.sployaltyPayment.sployalty ? product.sployaltyPayment.sployalty : '-'} SPL` : ''}` } />);
        }
        return false;
    }
}

export default ProductShow;