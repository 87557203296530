import React from 'react';
import {
    FunctionField,
    TextField,
    ReferenceField
} from 'react-admin';
import CustomTableList from './CustomTableList';
import NewOrderField from '../fields/NewOrderField';

class OrderList extends CustomTableList {

    optionRenderer = player => player.firstName ? `${player.firstName} ${player.lastName ? player.lastName : ''}`: null;

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'wechatPayment' || fieldName === 'viewed') {
            return true;
        } else if (fieldName === 'player') {
            console.log('oooo')
            fieldList.push(<NewOrderField/>)
            fieldList.push(<ReferenceField label="Player" source="player" reference="players" link="show">
                <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
            </ReferenceField>)
            return true;
        } else if (fieldName === 'product') {
            fieldList.push(<ReferenceField source={fieldName} reference="products" link="show">
                <TextField source="name.en" />
            </ReferenceField>)
            return true;
        } else if (fieldName === 'store') {
            fieldList.push(<ReferenceField source={fieldName} reference="stores" link="show">
                <TextField source="name.en" />
            </ReferenceField>)
            return true;
        } else if (fieldName === 'paymentAmount') {
            fieldList.push(<TextField label="Payment (CNY)" source='paymentAmount.yuan'/>)
            fieldList.push(<TextField label="Payment (loyalty)" source='paymentAmount.sployalty'/>)
        } else if (fieldName === 'recipientInfo') {
            fieldList.push(<TextField label="Recipient name" source='recipientInfo.name'/>)
            fieldList.push(<TextField label="Recipient phone" source='recipientInfo.phoneNumber'/>)
            return true;
        }
        return false;
    }

}

export default OrderList;