import React from 'react';

import CustomTableShow from './CustomTableShow';
import ImageFromAPIField from '../fields/ImageFromAPIField';


class ClubShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'logo') {
            fieldList.push(<ImageFromAPIField label="Logo" source="logo" field="uri" folder="club-logo" height="250"/>);
            return true;
        } else if (fieldName === 'picture') {
            fieldList.push(<ImageFromAPIField label="Picture" source="picture" field="uri" folder="club-picture" height="250"/>);
            return true;
        }
        return false;
    }
}

export default ClubShow;