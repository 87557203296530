import React from 'react';
import {
    ReferenceField,
    FunctionField,
    ArrayField,
    Datagrid,
    NumberField
} from 'react-admin';
import CustomTableShow from './CustomTableShow';


class MatchShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'submittedBy' || fieldName === 'validatedBy' || fieldName === 'fromDraft') {
            return true;
        } else if (fieldName === 'teamA') {
            fieldList.push(<ReferenceField label="Team A - player 1" source="teamA.player1" reference="players" link="show">
                <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
            </ReferenceField>);
            fieldList.push(<ReferenceField label="Team A - player 2" source="teamA.player2" reference="players" link="show">
                <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
            </ReferenceField>);

            return true;
        } else if (fieldName === 'teamB') {
            fieldList.push(<ReferenceField label="Team B - player 1" source="teamB.player1" reference="players" link="show">
                <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
            </ReferenceField>);
            fieldList.push(<ReferenceField label="Team B - player 2" source="teamB.player2" reference="players" link="show">
                <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'sets') {
            fieldList.push(<ArrayField source="sets">
                <Datagrid linkType={false}>
                    <NumberField label="Team A score" source="teamAScore"/>
                    <NumberField label="Team B score" source="teamBScore"/>
                </Datagrid>
            </ArrayField>);

        } else if (fieldName === 'results') {
            fieldList.push(<ArrayField source="results">
                <Datagrid linkType={false}>
                    <NumberField label="Position" source="position"/>
                    <ReferenceField label="Player" source="player" reference="players" link="show">
                        <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                    </ReferenceField>
                    <NumberField label="Points scored" source="pointsScored"/>
                    <NumberField label="Points conceded" source="pointsConceded"/>
                    <NumberField label="SPP" source="spp"/>
                </Datagrid>
            </ArrayField>);
            return true;
        }
        return false;
    }
}

export default MatchShow;