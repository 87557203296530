import React from 'react';

const ImageFromURLField = ({ record, field, source, folder, height }) => {

    if (record && source && record[source]) {
        return (
            <div>
                <img src={record[source]} height={height} />
            </div>
        );
    }
    return <div></div>;



};

export default ImageFromURLField;