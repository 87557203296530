import React from 'react';
import {
    FunctionField,
    Show,
    SimpleShowLayout,
    ArrayField,
    Datagrid,
    NumberField,
    ReferenceField,
    TextField,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ApproveMatchButton from '../fields/ApproveMatchButton'

class MatchDraftList extends CustomTableList {

    optionRenderer = player => player.firstName ? `${player.firstName} ${player.lastName ? player.lastName : ''}`: null;

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'teams' || fieldName === 'matches' || fieldName === 'results') {

            return true;
        } else if (fieldName === 'submittedBy') {
            fieldList.push(<ReferenceField label="Submitted by" source="submittedBy" reference="players" link="show">
                <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'teamA') {
            fieldList.push(<ReferenceField label="Team A - player 1" source="teamA.player1" reference="players" link="show">
                <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
            </ReferenceField>)
            fieldList.push(<ReferenceField label="Team A - player 2" source="teamA.player2" reference="players" link="show">
                <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
            </ReferenceField>)

            return true;
        } else if (fieldName === 'teamB') {
            fieldList.push(<ReferenceField label="Team B - player 1" source="teamB.player1" reference="players" link="show">
                <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
            </ReferenceField>)
            fieldList.push(<ReferenceField label="Team B - player 2" source="teamB.player2" reference="players" link="show">
                <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
            </ReferenceField>)
            return true;
        } else if (fieldName === 'sets') {
            return true;
        } else if (fieldName === 'status') {
            fieldList.push(<TextField source={fieldName}/>)
            fieldList.push(<ApproveMatchButton label="Validate match" status="validated"/>)
            fieldList.push(<ApproveMatchButton label="Refuse match" status="refused"/>)
            return true;
        }
        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>

                <ArrayField source="sets">
                    <Datagrid linkType={false}>
                        <NumberField label="Team A score" source="teamAScore"/>
                        <NumberField label="Team B score" source="teamBScore"/>
                    </Datagrid>
                </ArrayField>

                <ArrayField source="results">
                    <Datagrid linkType={false}>
                        <NumberField label="Position" source="position"/>
                        <ReferenceField label="Player" source="player" reference="players" link="show">
                            <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                        </ReferenceField>
                        <NumberField label="Points scored" source="pointsScored"/>
                        <NumberField label="Points conceded" source="pointsConceded"/>
                        <NumberField label="SPP" source="spp"/>
                    </Datagrid>
                </ArrayField>

            </SimpleShowLayout>
        </Show>;
    }

    defaultFilter(props) {
        return { status: 'pending' }
    }
}

export default MatchDraftList;