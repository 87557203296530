import CustomTableList from './CustomTableList';

class RankingList extends CustomTableList {

    optionRenderer = player => player.firstName ? `${player.firstName} ${player.lastName ? player.lastName : ''}`: null;

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'rankingMale' || fieldName === 'rankingFemale' || fieldName === 'rankingClub') {
            return true;
        }
        return false;
    }

}

export default RankingList;