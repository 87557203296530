import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { translate, crudDelete, startUndoable } from 'ra-core';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import RefreshIcon from '@material-ui/icons/Refresh';

import { Button, useRefresh } from 'react-admin';

const styles = (theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    }
  }
});

class ResetSPLButtonWithConfirmation extends Component {
  state = {
    showDialog: false
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };
  
  handleDelete = (event) => {
    event.preventDefault();
    this.setState({ showDialog: false });
    const { dispatchCrudDelete, startUndoable, resource, record, basePath, redirect, undoable } = this.props;
    if (undoable) {
      startUndoable(crudDelete(resource, record.id, record, basePath, redirect));
    } else {
      dispatchCrudDelete(resource, record.id, record, basePath, redirect);
    }
  };

  resetSPF = () => {
    const headers = new Headers({ Accept: 'application/json' });
    const token = localStorage.getItem('token');
    headers.set('Authorization', `JWT ${token}`);

    fetch(`${process.env.REACT_APP_API_URL}/players/spf/reset`, { method: 'GET', headers }).then((response) => {
        console.log('DONE');
        useRefresh()
        this.setState({ showDialog: false });
      }).catch((e) => {
        console.log('Warning: Error: comment not approved');
        this.setState({ showDialog: false });
      });
    console.log('ApproveButton clicked');
}

  render() {
    const { showDialog } = this.state;
    return (
      <Fragment>
        <Button onClick={this.handleClick} label="Reset SPF" key="button">
          <RefreshIcon />
        </Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="Are you sure?">
          <DialogTitle>Are you sure you want to reset all players SPF back to 0 ?</DialogTitle>

          <DialogContent>
            <div>
              After reset, you will not be able to undo
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.resetSPF} label="Reset SPF">
              <RefreshIcon />
            </Button>

            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

ResetSPLButtonWithConfirmation.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  dispatchCrudDelete: PropTypes.func.isRequired,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string.isRequired,
  startUndoable: PropTypes.func,
  translate: PropTypes.func,
  undoable: PropTypes.bool
};

ResetSPLButtonWithConfirmation.defaultProps = {
  redirect: 'list',
  undoable: true
};

export default compose(
  connect(
    null,
    { startUndoable, dispatchCrudDelete: crudDelete }
  ),
  translate,
  withStyles(styles)
)(ResetSPLButtonWithConfirmation);