// import React from 'react';

import CustomTableList from './CustomTableList';

class ClubList extends CustomTableList {


    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'role' || fieldName === 'password' || fieldName === 'disabled') {
            return true;
        }
        return false;
    }
}

export default ClubList;