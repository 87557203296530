// import React from 'react';

import CustomTableEdit from './CustomTableEdit';

class UserEdit extends CustomTableEdit {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'role' || fieldName === 'disabled') {
            return true;
        }
        return false;
    }
}

export default UserEdit;