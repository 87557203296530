import React from 'react';
import {
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    TextInput,
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';


class EventDraftEdit extends CustomTableEdit {

    optionRenderer = player => player.firstName ? `${player.firstName} ${player.lastName ? player.lastName : ''}`: null;
    optionRenderer2 = player => !player ? "" : player.firstName ? `${player.firstName} ${player.lastName}`: null;
    
    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'results' || fieldName === 'submittedBy' || fieldName === 'status') {
            return true;

        } else if (fieldName === 'teams') {

            fieldList.push(<ArrayInput source={fieldName}>
                <SimpleFormIterator>
                    <TextInput label="Short name" source="shortName"/>
                    <ReferenceInput label="Player 1" source="player1" reference="players" allowEmpty>
                        <AutocompleteInput optionText={this.optionRenderer} />
                    </ReferenceInput>
                    <ReferenceInput label="Player 2" source="player2" reference="players" allowEmpty>
                        <AutocompleteInput optionText={this.optionRenderer} />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>)
            return true;
        } else if (fieldName === 'americanoRounds') {
            fieldList.push(<ArrayInput source="americanoRounds" label="Matches (americano)">
                <SimpleFormIterator>
                <NumberInput label="Round number" source="roundNumber"/>

                <ArrayInput source="matches">
                    <SimpleFormIterator>
                        <ReferenceInput label="Team 1 Player 1" source="team1Player1" reference="players" allowEmpty>
                            <AutocompleteInput optionText={this.optionRenderer} />
                        </ReferenceInput>
                        <ReferenceInput label="Team 1 Player 2" source="team1Player2" reference="players" allowEmpty>
                            <AutocompleteInput optionText={this.optionRenderer} />
                        </ReferenceInput>
                        <ReferenceInput label="Team 2 Player 1" source="team2Player1" reference="players" allowEmpty>
                            <AutocompleteInput optionText={this.optionRenderer} />
                        </ReferenceInput>
                        <ReferenceInput label="Team 2 Player 2" source="team2Player2" reference="players" allowEmpty>
                            <AutocompleteInput optionText={this.optionRenderer} />
                        </ReferenceInput>
                        <NumberInput label="Team 1 score" source="team1Score"/>
                        <NumberInput label="Team 2 score" source="team2Score"/>
                            
                    </SimpleFormIterator>
                </ArrayInput>
                
                </SimpleFormIterator>
            </ArrayInput>)
            return true;
        } else if (fieldName === 'sets') {
            fieldList.push(<ArrayInput source={fieldName}>
                <SimpleFormIterator>
                    <NumberInput label="Team A score" source="teamAScore"/>
                    <NumberInput label="Team B score" source="teamBScore"/>
                </SimpleFormIterator>
            </ArrayInput>)
            return true;
        } else if (fieldName === 'players') {
            fieldList.push(<ReferenceArrayInput label="Players" source="players" reference="players" allowEmpty>
                <AutocompleteArrayInput optionText={this.optionRenderer2} />
            </ReferenceArrayInput>)
            return true
        }
        return false;
    }
}

export default EventDraftEdit;