import React from 'react';
import {
    FunctionField,
    Show,
    SimpleShowLayout,
    ArrayField,
    Datagrid,
    NumberField,
    ReferenceArrayField,
    ReferenceField,
    SingleFieldList,
    TextField,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import PlayerChipField from '../fields/PlayerChipField';
import ApproveEventButton from '../fields/ApproveEventButton'

class EventDraftList extends CustomTableList {

    optionRenderer = player => player.firstName ? `${player.firstName} ${player.lastName ? player.lastName : ''}`: null;

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'teams' || fieldName === 'matches' || fieldName === 'results' || fieldName === 'americanoRounds' || fieldName === 'pointsPerMatch') {

            return true;
        } else if (fieldName === 'submittedBy') {
            fieldList.push(<ReferenceField label="Submitted by" source="submittedBy" reference="players" link="show">
                <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
            </ReferenceField>);
            return true;
        } else if (fieldName === 'players') {
            fieldList.push(<ReferenceArrayField label="Players" source="players" reference="players" link="show">
                <SingleFieldList>
                    <PlayerChipField/>
                </SingleFieldList>
            </ReferenceArrayField>)
            return true;
        } else if (fieldName === 'sets') {
            return true;
        } else if (fieldName === 'status') {
            fieldList.push(<TextField source={fieldName}/>)
            fieldList.push(<ApproveEventButton label="Validate match" status="validated"/>)
            fieldList.push(<ApproveEventButton label="Refuse match" status="refused"/>)
            return true;
        }
        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log(props);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>

                <ArrayField source="teams">
                    <Datagrid linkType={false}>
                        <NumberField label="Name" source="shortName"/>
                        <ReferenceField label="Player 1" source="player1" reference="players" link="show">
                            <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                        </ReferenceField>
                        <ReferenceField label="Player 2" source="player2" reference="players" link="show">
                            <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                        </ReferenceField>
                    </Datagrid>
                </ArrayField>

                <ArrayField source="matches">
                    <Datagrid linkType={false}>
                        <FunctionField label="Teams" render={match => `${match.team1} vs ${match.team2}`} />
                        <FunctionField label="Score" render={match => `${match.team1Score} - ${match.team2Score}`} />

                    </Datagrid>
                </ArrayField>

                <ArrayField source="americanoRounds" label="Matches (americano)">
                    <Datagrid linkType={false}>
                        <NumberField label="Round number" source="roundNumber"/>
    
                        <ArrayField source="matches">
                            <Datagrid linkType={false}>
                                <ReferenceField label="Team 1 Player 1" source="team1Player1" reference="players" link="show">
                                    <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                                </ReferenceField>
                                <ReferenceField label="Team 1 Player 2" source="team1Player2" reference="players" link="show">
                                    <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                                </ReferenceField>
                                <ReferenceField label="Team 2 Player 1" source="team2Player1" reference="players" link="show">
                                    <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                                </ReferenceField>
                                <ReferenceField label="Team 2 Player 2" source="team2Player2" reference="players" link="show">
                                    <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                                </ReferenceField>
                                <NumberField label="Team 1 score" source="team1Score"/>
                                <NumberField label="Team 2 score" source="team2Score"/>
                                    
                            </Datagrid>
                        </ArrayField>
                        
                    </Datagrid>
                </ArrayField>

                <ArrayField source="results">
                    <Datagrid linkType={false}>
                        <NumberField label="Position" source="position"/>
                        <ReferenceField label="Player" source="player" reference="players" link="show">
                            <FunctionField render={player => `${player.firstName} ${player.lastName}`} />
                        </ReferenceField>
                        <NumberField label="Points scored" source="pointsScored"/>
                        <NumberField label="Points conceded" source="pointsConceded"/>
                        <NumberField label="SPP" source="spp"/>
                    </Datagrid>
                </ArrayField>

            </SimpleShowLayout>
        </Show>;
    }

    defaultFilter(props) {
        return { status: 'pending' }
    }
}

export default EventDraftList;