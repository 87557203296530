import React from 'react';
import {
    TextInput
} from 'react-admin';

import CustomTableEdit from './CustomTableEdit';
import MyInputFile from '../fields/MyInputFile';


class PersonEdit extends CustomTableEdit {

    optionRenderer = player => player.firstName ? `${player.firstName} ${player.lastName ? player.lastName : ''}`: null;
    
    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'bestRank' || fieldName === 'opponentList' || fieldName === 'partnerList' ||
        fieldName === 'latestEvents'|| fieldName === 'latestMatches' || fieldName === 'clubList' ||
        fieldName === 'loyaltyPoints' || fieldName === 'loyaltyPointHistory' || fieldName === 'history' || fieldName === 'wechatProfile' ||
        fieldName === 'matchPenalties' || fieldName === 'eventPenalties' || fieldName === 'rank' || fieldName === 'rankingHistory' ||
        fieldName === 'spp' || fieldName === 'spl' || fieldName === 'spf' || fieldName === 'nationality' || fieldName === 'fullName') {
            return true;
        } else if (fieldName === 'openid') {
            if (this.props.permissions === 'super-admin' || this.props.permissions === 'admin') {
                fieldList.push(<TextInput source={fieldName} label="wechat openid"/>)
            }
            return true;
        } else if (fieldName === 'profile') {
            fieldList.push(<MyInputFile source="profile" label="Profile" folder="player-profile" />);
            return true;
        }
        return false;
    }
}

export default PersonEdit;