import React from 'react';
import {
    ReferenceField,
    FunctionField,
    ArrayField,
    Datagrid,
    NumberField,
    ReferenceArrayField,
    SingleFieldList
} from 'react-admin';
import CustomTableShow from './CustomTableShow';
import PlayerChipField from '../fields/PlayerChipField';


class EventShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'submittedBy' || fieldName === 'validatedBy' || fieldName === 'fromDraft') {
            return true;
        } else if (fieldName === 'teams') {
            fieldList.push(<ArrayField source="teams">
                <Datagrid linkType={false}>
                    <NumberField label="Name" source="shortName"/>
                    <ReferenceField label="Player 1" source="player1" reference="players" link="show">
                        <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                    </ReferenceField>
                    <ReferenceField label="Player 2" source="player2" reference="players" link="show">
                        <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                    </ReferenceField>
                </Datagrid>
            </ArrayField>);


            return true;
        } else if (fieldName === 'matches') {
            fieldList.push(<ArrayField source="matches">
                <Datagrid linkType={false}>
                    <FunctionField label="Teams" render={(match) => `${match.team1} vs ${match.team2}`} />
                    <FunctionField label="Score" render={(match) => `${match.team1Score} - ${match.team2Score}`} />

                </Datagrid>
            </ArrayField>);
            return true;
        } else if (fieldName === 'americanoRounds') {
            fieldList.push(<ArrayField source="americanoRounds" label="Matches (americano)">
                <Datagrid linkType={false}>
                    <NumberField label="Round number" source="roundNumber"/>

                    <ArrayField source="matches">
                        <Datagrid linkType={false}>
                            <ReferenceField label="Team 1 Player 1" source="team1Player1" reference="players" link="show">
                                <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                            </ReferenceField>
                            <ReferenceField label="Team 1 Player 2" source="team1Player2" reference="players" link="show">
                                <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                            </ReferenceField>
                            <ReferenceField label="Team 2 Player 1" source="team2Player1" reference="players" link="show">
                                <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                            </ReferenceField>
                            <ReferenceField label="Team 2 Player 2" source="team2Player2" reference="players" link="show">
                                <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                            </ReferenceField>
                            <NumberField label="Team 1 score" source="team1Score"/>
                            <NumberField label="Team 2 score" source="team2Score"/>
                                
                        </Datagrid>
                    </ArrayField>
                    
                </Datagrid>
            </ArrayField>)
            return true;
            
        } else if (fieldName === 'players') {
            fieldList.push(<ReferenceArrayField label="Players" source="players" reference="players" link="show">
                <SingleFieldList>
                    <PlayerChipField/>
                </SingleFieldList>
            </ReferenceArrayField>);
            return true;

        } else if (fieldName === 'results') {
            fieldList.push(<ArrayField source="results">
                <Datagrid linkType={false}>
                    <NumberField label="Position" source="position"/>
                    <ReferenceField label="Player" source="player" reference="players" link="show">
                        <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                    </ReferenceField>
                    <NumberField label="Points scored" source="pointsScored"/>
                    <NumberField label="Points conceded" source="pointsConceded"/>
                    <NumberField label="Score" source="score"/>
                    <NumberField label="SPP" source="spp"/>
                </Datagrid>
            </ArrayField>);
            return true;
        }
        return false;
    }
}

export default EventShow;