import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// import { change, submit, isSubmitting } from 'redux-form';
import { isSubmitting } from 'redux-form';
import {
    fetchEnd,
    fetchStart,
    required,
    showNotification,
    // crudGetMatching,
    Button,
    // SaveButton,
    SimpleForm,
    // FormWithRedirect,
    // TextInput,
    NumberInput,
    // LongTextInput,
    // CREATE,
    // REDUX_FORM_NAME
} from 'react-admin';
// import IconContentAdd from '@material-ui/icons/Add';
// import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogActions from '@material-ui/core/DialogActions';

import PropTypes from 'prop-types';
// import Button from '@material-ui/core/Button';
import { push } from 'react-router-redux';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    validate: { backgroundColor: '#00FF0055' },
    refuse: { backgroundColor: '#FF000055' }
});

class GrantLoyaltyButton extends Component {
    state = {
        error: false,
        showDialog: false
    };


    handleClick = (e) => {

        e.stopPropagation();
        this.setState({ showDialog: true });

        // const { push, record, showNotification, status } = this.props;
        // console.log(status)

        // const token = localStorage.getItem('token');

        // const headers = new Headers({
        //     Accept: 'application/json',
        //     Authorization: `JWT ${token}`,
        //     'Content-Type': 'application/json'
        // });

        // fetch(`${process.env.REACT_APP_API_URL}/customers/${record.id}/impersonate`, { method: 'GET', headers })
        // .then((response) => response.json())
        // .then((data) => {
        //     console.log(data)
        //     if (data.token) {
        //         // showNotification('Renew order draft created');
        //         // push(`/orders/${data.id}`);
        //         // push(`${process.env.REACT_APP_FRONT_URL}/impersonate?token=${data.token}&refreshToken=${data.refreshToken}`);
        //         window.location.href = `${process.env.REACT_APP_FRONT_URL}/impersonate?token=${data.token}&refreshToken=${data.refreshToken}`
        //     } else {
        //         showNotification('Impersonate refused');
        //     }
        // }).catch((e) => {
        //     console.error(e)
        //     showNotification('Error: Impersonation unsuccessful', 'warning')
        // });
    }

    handleCloseClick = (e) => {
        e.stopPropagation();
        this.setState({ showDialog: false });
    };

    handleSaveClick = (e) => {
        e.stopPropagation();
        // const { submit } = this.props;


        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        // submit('player-loyalty-add');
    };

    handleSubmit = (form) => {

        const {
            change,
            crudGetMatching,
            showNotification,
            push,
            status,
            record
        } = this.props;

        fetchStart();
        console.log("OK")

        console.log(status)

        const token = localStorage.getItem('token');

        const headers = new Headers({
            Accept: 'application/json',
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json'
        });

        fetch(`${process.env.REACT_APP_API_URL}/players/${record.id}/loyalty`, { method: 'POST', headers, body: JSON.stringify({ points: form.points }) })
        .then((response) => response.json())
        .then((data) => {
            console.log(data)
            showNotification('Loyalty points granted');
            push(`/players/${record.id}/show`);
            // push(`/players`);
            // push(`${process.env.REACT_APP_FRONT_URL}/impersonate?token=${data.token}&refreshToken=${data.refreshToken}`);
            // window.location.href = `${process.env.REACT_APP_FRONT_URL}/impersonate?token=${data.token}&refreshToken=${data.refreshToken}`
        }).catch((e) => {
            console.error(e)
            showNotification('Error: Grant unsuccessful', 'warning')
        }).finally(() => {
            this.setState({ showDialog: false });

            fetchEnd();
        })


        // // Dispatch an action letting react-admin know a API call is ongoing
        // fetchStart();

        // // As we want to know when the new post has been created in order to close the modal, we use the
        // // dataProvider directly
        // dataProvider(CREATE, 'posts', { data: values })
        //     .then(({ data }) => {
        //         // Refresh the choices of the ReferenceInput to ensure our newly created post
        //         // always appear, even after selecting another post
        //         crudGetMatching(
        //             'posts',
        //             'comments@post_id',
        //             { page: 1, perPage: 25 },
        //             { field: 'id', order: 'DESC' },
        //             {}
        //         );

        //         // Update the main react-admin form (in this case, the comments creation form)
        //         change(REDUX_FORM_NAME, 'post_id', data.id);
        //         this.setState({ showDialog: false });
        //     })
        //     .catch(error => {
        //         showNotification(error.message, 'error');
        //     })
        //     .finally(() => {
        //         // Dispatch an action letting react-admin know a API call has ended
        //         fetchEnd();
        //     });
    };

    stopLabelPropagation(e) {
        e.stopPropagation();
    }

    render() {
        const { showDialog } = this.state;
        const { isSubmitting } = this.props;

        return (
            <Fragment>
                <Button className="grant-loyalty-button" onClick={this.handleClick} label={this.props.label}>
                    {/* <IconContentAdd /> */}
                </Button>
                <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    onClick={ this.stopLabelPropagation }
                    aria-label="Add Loyality points"
                >
                    <DialogTitle>Add Loyalty points</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            // We override the redux-form name to avoid collision with the react-admin main form
                            // form="player-loyalty-add"
                            resource="players"
                            // We override the redux-form onSubmit prop to handle the submission ourselves
                            // onSubmit={this.handleSubmit}
                            save={this.handleSubmit}
                            // We want no toolbar at all as we have our modal actions
                            // toolbar={null}
                        >
                            <NumberInput source="points" validate={required()} />
                        </SimpleForm>
                    </DialogContent>
                    {/* <DialogActions>
                        <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        />
                        <Button
                            label="ra.action.cancel"
                            onClick={this.handleCloseClick}
                        >
                            <IconCancel />
                        </Button>
                    </DialogActions> */}
                </Dialog>
            </Fragment>
        );
    }

    // render() {

    //     return <Button className="grant-loyalty-button" label="Impersonate" onClick={this.handleClick}>{this.props.label}</Button>;
    // }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('player-loyalty-add')(state)
});

// const mapDispatchToProps = {
//     change,
//     crudGetMatching,
//     fetchEnd,
//     fetchStart,
//     showNotification,
//     submit,
//     push
// };

GrantLoyaltyButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

// export default connect(mapStateToProps, mapDispatchToProps)(
//     GrantLoyaltyButton
// );
export default connect(mapStateToProps, {
    showNotification,
    push,
})(GrantLoyaltyButton);

// export default GrantLoyaltyButton;
