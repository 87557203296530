import React from 'react';
import {
    ReferenceField,
    FunctionField,
    ArrayField,
    Datagrid,
    NumberField
} from 'react-admin';
import CustomTableShow from './CustomTableShow';


class MatchShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'rankingMale') {
            fieldList.push(<ArrayField source="rankingMale">
                <Datagrid linkType={false}>
                    <NumberField label="Rank" source="rank"/>
                    <ReferenceField label="Player" source="player" reference="players" link="show">
                        <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                    </ReferenceField>
                    <NumberField label="SPL" source="spl"/>
                    <NumberField label="Move" source="move"/>
                </Datagrid>
            </ArrayField>);

            return true;
        } else if (fieldName === 'rankingFemale') {
            fieldList.push(<ArrayField source="rankingFemale">
                <Datagrid linkType={false}>
                    <NumberField label="Rank" source="rank"/>
                    <ReferenceField label="Player" source="player" reference="players" link="show">
                        <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                    </ReferenceField>
                    <NumberField label="SPL" source="spl"/>
                    <NumberField label="Move" source="move"/>
                </Datagrid>
            </ArrayField>);

            return true;
        } else if (fieldName === 'rankingClub') {
            fieldList.push(<ArrayField source="rankingClub">
                <Datagrid linkType={false}>
                    <NumberField label="Rank" source="rank"/>
                    <ReferenceField label="Club" source="club" reference="clubs" link="show">
                        <FunctionField render={(club) => `${club.nickname}`} />
                    </ReferenceField>
                    <NumberField label="Matches" source="matches"/>
                    <NumberField label="Events" source="events"/>
                </Datagrid>
            </ArrayField>);

            return true;
        } else if (fieldName === 'moverOfTheWeek') {
            fieldList.push(<ReferenceField label="Mover of the week" source="moverOfTheWeek.player" reference="players" link="show">
                <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
            </ReferenceField>);
            fieldList.push(<NumberField label="Mover of the week (move)" source="moverOfTheWeek.move"/>);
            return true;
        }
        return false;
    }
}

export default MatchShow;