import React from 'react';
import {
    FunctionField,
    ArrayField,
    Datagrid,
    ReferenceField,
    NumberField,
    DateField,
    TextField,
    BooleanField
} from 'react-admin';
import CustomTableShow from './CustomTableShow';
import ImageFromAPIField from '../fields/ImageFromAPIField';
import ImageFromURLField from '../fields/ImageFromURLField';
import BestRankField from '../fields/BestRankField';

class PlayerShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'paymentMethod' || fieldName === 'fullName') {
            return true;
        } else if (fieldName === 'clubList') {
            fieldList.push(<ArrayField source="partnerList">
                <Datagrid linkType={false}>
                    <ReferenceField label="Player" source="partner" reference="players" link="show">
                        <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                    </ReferenceField>

                    <NumberField source="matchAmount"/>
                    <NumberField source="eventAmount"/>
                </Datagrid>
            </ArrayField>);

            fieldList.push(<ArrayField source="opponentList">
                <Datagrid linkType={false}>
                    <ReferenceField label="Player" source="opponent" reference="players" link="show">
                        <FunctionField render={(player) => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                    </ReferenceField>

                    <NumberField source="matchAmount"/>
                    <NumberField source="eventAmount"/>
                </Datagrid>
            </ArrayField>);

            fieldList.push(<ArrayField source="clubList">
                <Datagrid linkType={false}>
                    <ReferenceField label="Club" source="club" reference="clubs" link="show">
                        <FunctionField render={(club) => `${club.nickname}`} />
                    </ReferenceField>

                    <NumberField source="matchAmount"/>
                    <NumberField source="eventAmount"/>
                </Datagrid>
            </ArrayField>);
            return true;
        } else if (fieldName === 'latestEvents') {
            fieldList.push(<ArrayField source="latestEvents">
                <Datagrid linkType={false}>
                    <ReferenceField label="Event" source="event" reference="events" link="show">
                        <FunctionField render={(event) => {
                            const date = new Date(event.date);
                            return `${date.toLocaleDateString('en-US')} (${event.players.length} players)`;
                        }} />
                    </ReferenceField>

                    <NumberField source="score"/>
                </Datagrid>
            </ArrayField>);
            return true;
        } else if (fieldName === 'latestMatches') {
            fieldList.push(<ArrayField source="latestMatches">
                <Datagrid linkType={false}>
                    <ReferenceField label="Match" source="match" reference="matches" link="show">
                        <FunctionField render={(match) => {
                            const date = new Date(match.date);
                            return `${date.toLocaleDateString('en-US')} `;
                        }} />
                    </ReferenceField>

                    <NumberField source="score"/>
                </Datagrid>
            </ArrayField>);

            fieldList.push(<ArrayField source="history">
                <Datagrid linkType={false}>
                    <DateField source="date"/>
                    <TextField source="type"/>
                    <ReferenceField label="Match" source="match" reference="matches" link="show">
                        <FunctionField render={(match) => {
                            const date = new Date(match.date);
                            return `${date.toLocaleDateString('en-US')} `;
                        }} />
                    </ReferenceField>
                    <ReferenceField label="Event" source="event" reference="events" link="show">
                        <FunctionField render={(event) => {
                            const date = new Date(event.date);
                            return `${date.toLocaleDateString('en-US')} (${event.players.length} players)`;
                        }} />
                    </ReferenceField>
                    <BooleanField source="won"/>
                    <BooleanField source="last"/>
                    <NumberField source="spp"/>
                </Datagrid>
            </ArrayField>);
            return true;
        } else if (fieldName === 'bothPayment') {
            fieldList.push(<FunctionField label="Price 2" render={(product) => `${product.bothPayment.yuan ? product.bothPayment.yuan : '-'} ¥ + ${product.bothPayment.sployalty ? product.bothPayment.sployalty : '-'} SPL`} />);
            return true;
        } else if (fieldName === 'profile') {
            fieldList.push(<ImageFromAPIField label="Profile" source="profile" field="uri" folder="player-profile" height="350"/>);
            return true;
        } else if (fieldName === 'wechatProfile') {
            fieldList.push(<ImageFromURLField source={fieldName} height={350}/>);
            return true;
        } else if (fieldName === 'loyaltyPointHistory') {
            // fieldList.push(<ArrayField source="loyaltyPointHistory">
            //     <Datagrid linkType={false}>
            //         <DateField source="date"/>
            //         <TextField source="type"/>
            //         <NumberField source="points"/>
            //     </Datagrid>
            // </ArrayField>);
            return true;
        } else if (fieldName === 'rankingHistory') {
            return true;
        } else if (fieldName === 'bestRank') {
            fieldList.push(<BestRankField source={fieldName}/>)
            return true
        }

        return false;
    }
}

export default PlayerShow;