// import React from 'react';
// import {
//     ArrayInput,
//     SimpleFormIterator,
//     NumberInput,
//     ReferenceInput,
//     AutocompleteInput
// } from 'react-admin';
import CustomTableCreate from './CustomTableCreate';

class UserCreate extends CustomTableCreate {

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'role' || fieldName === 'disabled') {
            return true;
        }
        return false;
    }
}

export default UserCreate;