import React from 'react';
import {
    // FunctionField,
    ChipField
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles({
    male: { backgroundColor: '#3333FF55' },
    female: { backgroundColor: '#FF337755' }
});

const PlayerChipField = (props) => {
    const classes = useStyles();

    const isMale = (v) => v === 'male';
    const isFemale = (v) => v === 'female';

    console.log(props.record);
    console.log('gender');
    console.log(props.record.gender);
    return (
        <ChipField
            className={classnames({
                [classes.male]: isMale(props.record.gender),
                [classes.female]: isFemale(props.record.gender)
            })}
            record={{ fullname: `${props.record.firstName} ${props.record.lastName ? props.record.lastName : ''}` }}
            source="fullname"
            // {...props}
        />
    );
};

export default PlayerChipField;