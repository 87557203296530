import React from 'react';
import {
    FunctionField,
    ReferenceField,
    TextField,
} from 'react-admin';
import CustomTableShow from './CustomTableShow';

class OrderShow extends CustomTableShow {

    optionRenderer = player => player.firstName ? `${player.firstName} ${player.lastName ? player.lastName : ''}`: null;

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'wechatPayment' || fieldName === 'viewed') {
            return true;
        } else if (fieldName === 'player') {
            fieldList.push(<ReferenceField label="Player" source="player" reference="players" link="show">
                <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
            </ReferenceField>)
            return true;
        } else if (fieldName === 'product') {
            fieldList.push(<ReferenceField source={fieldName} reference="products" link="show">
                <TextField source="name.en" />
            </ReferenceField>)
            return true;
        } else if (fieldName === 'store') {
            fieldList.push(<ReferenceField source={fieldName} reference="stores" link="show">
                <TextField source="name.en" />
            </ReferenceField>)
            return true;
        } else if (fieldName === 'paymentAmount') {
            fieldList.push(<TextField label="Payment (CNY)" source='paymentAmount.yuan'/>)
            fieldList.push(<TextField label="Payment (loyalty)" source='paymentAmount.sployalty'/>)
        } else if (fieldName === 'recipientInfo') {
            fieldList.push(<TextField label="Recipient name" source='recipientInfo.name'/>)
            fieldList.push(<TextField label="Recipient phone" source='recipientInfo.phoneNumber'/>)
            return true;
        }
        return false;
    }

    componentDidMount() {

        var headers = new Headers({ Accept: 'application/json' });
        const token = localStorage.getItem('token');
        headers.set('Authorization', `JWT ${token}`);

        fetch(`${process.env.REACT_APP_API_URL}/orders/${this.props.id}/viewed`, { method: 'POST', headers: headers }).then((response) => {

            console.log('VIEWED')
            console.log(response)

        }).catch((e) => {
            console.log('Warning: Error: comment not approved');
        });

        return super.componentDidMount()
    }
}

export default OrderShow;