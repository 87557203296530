import React from 'react';
import {
    TextInput,
} from 'react-admin';
import CustomTableCreate from './CustomTableCreate';
import MyInputFile from '../fields/MyInputFile';

class ProductCreate extends CustomTableCreate {

    optionRenderer = player => player.firstName ? `${player.firstName} ${player.lastName ? player.lastName : ''}`: null;

    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'paymentMethod') {
            return true;
        } else if (fieldName === 'name') {
            fieldList.push(<TextInput label="Name (english)" source="name.en" />)
            fieldList.push(<TextInput label="Name (中文)" source="name.zh" />)

            return true;
        } else if (fieldName === 'description') {
            fieldList.push(<TextInput label="Description (english)" source="description.en" multiline={true} />)
            fieldList.push(<TextInput label="Description (中文)" source="description.zh" multiline={true} />)

            return true;
        } else if (fieldName === 'yuanPayment') {
            fieldList.push(<TextInput label="Price CNY only" source="yuanPayment.yuan"/>);
            return true;
        } else if (fieldName === 'bothPayment') {
            fieldList.push(<TextInput label="Price CNY (CNY + loyalty)" source="bothPayment.yuan"/>);
            fieldList.push(<TextInput label="Price loyalty (CNY + loyalty)" source="bothPayment.sployalty"/>);
            return true;
        } else if (fieldName === 'sployaltyPayment') {
            fieldList.push(<TextInput label="Price loyalty points only" source="sployaltyPayment.sployalty"/>);
            return true;
        } else if (fieldName === 'cover') {
            fieldList.push(<MyInputFile source="cover" label="Cover" folder="product-cover" />)
            return true;
        }
        return false;
    }
}

export default ProductCreate;