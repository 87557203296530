import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
    NumberField,
} from 'react-admin';

const BestRankField = ({ record, field, source, folder, height }) => {

    console.log(record)
    console.log(field)
    console.log(source)
    if (!record || !record[source] || record[source] <= 0) {
        return <div>Not ranked</div>;
    }

    return <NumberField source={source}/>;

};

BestRankField.defaultProps = {
    addLabel: true
};

BestRankField.propTypes = {
    // @ts-ignore
    ...Typography.propTypes
};

export default BestRankField;