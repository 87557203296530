import React from 'react';
import {
    ReferenceInput,
    AutocompleteInput,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
} from 'react-admin';
import CustomTableEdit from './CustomTableEdit';


class MatchDraftEdit extends CustomTableEdit {

    optionRenderer = player => player.firstName ? `${player.firstName} ${player.lastName ? player.lastName : ''}`: null;
    
    customHandled(fields, fieldName, fieldList) {
        console.log('customHandled');
        console.log(fieldName);

        if (fieldName === 'results' || fieldName === 'submittedBy' || fieldName === 'status') {
            return true;

        }else if (fieldName === 'teamA') {

            fieldList.push(<ReferenceInput label="Team A - Player 1" source="teamA.player1" reference="players" allowEmpty>
                <AutocompleteInput optionText={this.optionRenderer} />
            </ReferenceInput>)
            fieldList.push(<ReferenceInput label="Team A - Player 2" source="teamA.player2" reference="players" allowEmpty>
                <AutocompleteInput optionText={this.optionRenderer} />
            </ReferenceInput>)
            return true;
        } else if (fieldName === 'teamB') {

            fieldList.push(<ReferenceInput label="Team B - Player 1" source="teamB.player1" reference="players" allowEmpty>
                <AutocompleteInput optionText={this.optionRenderer} />
            </ReferenceInput>)
            fieldList.push(<ReferenceInput label="Team B - Player 2" source="teamB.player2" reference="players" allowEmpty>
                <AutocompleteInput optionText={this.optionRenderer} />
            </ReferenceInput>)
            return true;
        } else if (fieldName === 'sets') {
            fieldList.push(<ArrayInput source={fieldName}>
                <SimpleFormIterator>
                    <NumberInput label="Team A score" source="teamAScore"/>
                    <NumberInput label="Team B score" source="teamBScore"/>
                </SimpleFormIterator>
            </ArrayInput>)
            return true;
        }
        return false;
    }
}

export default MatchDraftEdit;