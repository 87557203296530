import React from 'react';

import CustomTableList from './CustomTableList';
import ImageFromAPIField from '../fields/ImageFromAPIField';

class ClubList extends CustomTableList {


    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'logo') {
            fieldList.push(<ImageFromAPIField label="Logo" source="logo" field="uri" folder="club-logo" height="50"/>);
            return true;
        } else if (fieldName === 'picture') {
            fieldList.push(<ImageFromAPIField label="Picture" source="picture" field="uri" folder="club-picture" height="250"/>);
            return true;
        }
        return false;
    }
}

export default ClubList;