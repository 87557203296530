import React from 'react';
import {
    TextField
} from 'react-admin';
import CustomTableShow from './CustomTableShow';


class CategoryShow extends CustomTableShow {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'name') {
            fieldList.push(<TextField label="Name (english)" source="name.en"/>);
            fieldList.push(<TextField label="Name (中文)" source="name.zh"/>);
            return true;
        }
        return false;
    }
}

export default CategoryShow;