import React from 'react';
import {
    TextField
} from 'react-admin';
import CustomTableList from './CustomTableList';

class StoreList extends CustomTableList {

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'name') {
            fieldList.push(<TextField label="Name (english)" source="name.en"/>);
            fieldList.push(<TextField label="Name (中文)" source="name.zh"/>);
            return true;
        } else if (fieldName === 'address') {
            fieldList.push(<TextField label="Address (english)" source="address.en"/>);
            fieldList.push(<TextField label="Address (中文)" source="address.zh"/>);
            return true;
        }
        return false;
    }

}

export default StoreList;