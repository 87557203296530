import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { push } from 'react-router-redux';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    validate: { backgroundColor: '#00FF0055' },
    refuse: { backgroundColor: '#FF000055' }
});

class ApproveMatchButton extends Component {

    handleClick = () => {
        const { push, record, showNotification, status } = this.props;
        console.log(status)
        console.log(status)
        console.log(status)
        console.log(status)
        console.log(status)
        console.log(status)
        console.log(status)
        console.log(status)
        console.log(status)

        const token = localStorage.getItem('token');

        const headers = new Headers({
            Accept: 'application/json',
            Authorization: `JWT ${token}`,
            'Content-Type': 'application/json'
        });

        fetch(`${process.env.REACT_APP_API_URL}/match-drafts/${record.id}/validate`, { method: 'POST', headers, body: JSON.stringify({ status: status }) }).then(() => {
            if (this.props.status === "validated") {
                showNotification('Match approved');
            } else {
                showNotification('Match refused');
            }
            push('/matches');
        }).catch((e) => {
            showNotification('Error: Match could not be updated', 'warning')
        });
    }

    render() {

        if (this.props.status === "validated") {
            return <Button className='match-validate' label="Approve" onClick={this.handleClick} />;
        } else {
            return <Button className='match-refuse' label="Refuse" onClick={this.handleClick} />;
        }
    }
}

ApproveMatchButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification,
    push,
})(ApproveMatchButton);