import React from 'react';
import {
    FunctionField,
    Show,
    SimpleShowLayout,
    Filter,
    TextInput,
    SelectInput,
    ArrayField,
    Datagrid,
    ReferenceField,
    NumberField,
    DateField,
    TextField,
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ImageFromURLField from '../fields/ImageFromURLField';
import ImageFromAPIField from '../fields/ImageFromAPIField';
import BestRankField from '../fields/BestRankField';
import PlayersResetSPLActions from '../actions/PlayersResetSPLActions';
import GrantLoyaltyButton from '../fields/GrantLoyaltyButton'

class PlayerList extends CustomTableList {

    optionRenderer = player => player.firstName ? `${player.firstName} ${player.lastName ? player.lastName : ''}`: null;

    myFilter = (props) => (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn />
            <SelectInput label="Gender" source="gender" allowEmpty
            choices={[
                { id: 'male', name: 'Male' },
                { id: 'female', name: 'Female' },
            ]}/>
        </Filter>
    );

    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'opponentList' || fieldName === 'partnerList' || fieldName === 'latestEvents' || fieldName === 'latestMatches' ||
        fieldName === 'openid' || fieldName === 'clubList' || fieldName === 'loyaltyPointHistory' || fieldName === 'history'||
        fieldName === 'birthdate' || fieldName === 'matchPenalties' || fieldName === 'eventPenalties' || fieldName === 'rankingHistory' || fieldName === 'fullName') {
            return true;
        } else if (fieldName === 'profile') {
            fieldList.push(<ImageFromAPIField label="Profile" source="profile" field="uri" folder="player-profile" height="50"/>);
            return true
        } else if (fieldName === 'wechatProfile') {
            fieldList.push(<ImageFromURLField source={fieldName} height={50}/>)
            return true
        } else if (fieldName === 'bestRank') {
            fieldList.push(<BestRankField source={fieldName}/>)
            return true
        } else if (fieldName === 'loyaltyPoints') {
            fieldList.push(<NumberField source={fieldName}/>);
            fieldList.push(<GrantLoyaltyButton label="Add loyalty"/>)
            return true;
        }
        return false;
    }

    PostShow(props) {
        console.log(props.record);
        console.log('-------------------');
        return <Show
            {...props}
            /* disable the app title change when shown */
            title=" " >
            <SimpleShowLayout {...props}>


            <DateField source="birthdate" />
            <TextField source="openid" />
            <TextField source="matchPenalties" />
            <TextField source="eventPenalties" />

                {/* <ArrayField source="partnerList">
                    <Datagrid linkType={false}>
                        <ReferenceField label="Player" source="player" reference="players" link="show">
                            <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                        </ReferenceField>

                        <NumberField source="matchAmount"/>
                        <NumberField source="eventAmount"/>
                    </Datagrid>
                </ArrayField>
                

                <ArrayField source="opponentList">
                    <Datagrid linkType={false}>
                        <ReferenceField label="Player" source="player" reference="players" link="show">
                            <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />
                        </ReferenceField>

                        <NumberField source="matchAmount"/>
                        <NumberField source="eventAmount"/>
                    </Datagrid>
                </ArrayField> */}

                <ArrayField source="latestEvents">
                    <Datagrid linkType={false}>
                        <ReferenceField label="Event" source="event" reference="events" link="show">
                            <FunctionField render={event => { 
                                const date = new Date(event.date)
                                return `${date.toLocaleDateString("en-US")} (${event.players.length} players)`
                                }} />                        
                            </ReferenceField>

                            <NumberField source="score"/>
                            <NumberField source="pointsScored"/>
                            <NumberField source="pointsConceded"/>
                    </Datagrid>
                </ArrayField>

                <ArrayField source="latestMatches">
                    <Datagrid linkType={false}>
                        <ReferenceField label="Match" source="match" reference="matches" link="show">
                            <FunctionField render={match => {
                                const date = new Date(match.date)
                                return `${date.toLocaleDateString("en-US")} `
                                }} />
                        </ReferenceField>

                        <NumberField source="score"/>
                        <NumberField source="pointsScored"/>
                        <NumberField source="pointsConceded"/>
                    </Datagrid>
                </ArrayField>
                


            </SimpleShowLayout>
        </Show>;
    }

    customActions = (props) => {
        console.log("passed here")
        return <PlayersResetSPLActions {...props}/>
    }
}

                // <ReferenceArrayField source="opponentList" reference="players">
                //     <SingleFieldList>
                //         <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />

                //     </SingleFieldList>
                // </ReferenceArrayField>

                // <ReferenceArrayField source="partnerList" reference="players">
                //     <SingleFieldList>
                //         <FunctionField render={player => `${player.firstName} ${player.lastName ? player.lastName : ''}`} />

                //     </SingleFieldList>
                // </ReferenceArrayField>

export default PlayerList;