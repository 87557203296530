/* eslint-disable complexity */
import * as React from 'react';
import { Admin, Resource } from 'react-admin';

import CategoryIcon from '@material-ui/icons/CheckBox';
import ProductIcon from '@material-ui/icons/AddAPhoto';
import StoreIcon from '@material-ui/icons/LocationCity';
import EventIcon from '@material-ui/icons/Camera';
import EventDraftIcon from '@material-ui/icons/CameraTwoTone';
import MatchIcon from '@material-ui/icons/Sports';
import MatchDraftIcon from '@material-ui/icons/SportsTwoTone';
import ClubIcon from '@material-ui/icons/Business';
import CityIcon from '@material-ui/icons/Map';
import PlayerIcon from '@material-ui/icons/PersonTwoTone';
import RankingIcon from '@material-ui/icons/Star';
import OrderIcon from '@material-ui/icons/FileCopy';
import UserIcon from '@material-ui/icons/Person';

import authProvider from './authProvider';
import dataProvider from './dataProvider';

import MyLoginPage from './pages/MyLoginPage';
// import Dashboard from './pages/Dashboard';

import './App.css';

import SinopadelTheme from './SinopadelTheme';

// import CustomTableCreate from './creates/CustomTableCreate';
// import CustomTableEdit from './edits/CustomTableEdit';
// import CustomTableList from './lists/CustomTableList';
// import CustomTableShow from './shows/CustomTableShow';

import CityCreate from './creates/CityCreate';
import CityEdit from './edits/CityEdit';
import CityList from './lists/CityList';
import CityShow from './shows/CityShow';

import ClubCreate from './creates/ClubCreate';
import ClubEdit from './edits/ClubEdit';
import ClubList from './lists/ClubList';
import ClubShow from './shows/ClubShow';

// import MatchCreate from './creates/MatchCreate';
// import MatchEdit from './edits/MatchEdit';
import MatchList from './lists/MatchList';
import MatchShow from './shows/MatchShow';

import MatchDraftEdit from './edits/MatchDraftEdit';
import MatchDraftList from './lists/MatchDraftList';
import MatchDraftShow from './shows/MatchDraftShow';

import OrderList from './lists/OrderList';
import OrderShow from './shows/OrderShow';

// import EventCreate from './creates/EventCreate';
// import EventEdit from './edits/EventEdit';
import EventList from './lists/EventList';
import EventShow from './shows/EventShow';

import EventDraftEdit from './edits/EventDraftEdit';
import EventDraftList from './lists/EventDraftList';
import EventDraftShow from './shows/EventDraftShow';

import PlayerCreate from './creates/PlayerCreate';
import PlayerEdit from './edits/PlayerEdit';
import PlayerList from './lists/PlayerList';
import PlayerShow from './shows/PlayerShow';

import RankingShow from './shows/RankingShow';
import RankingList from './lists/RankingList';

import CategoryCreate from './creates/CategoryCreate';
import CategoryEdit from './edits/CategoryEdit';
import CategoryList from './lists/CategoryList';
import CategoryShow from './shows/CategoryShow';

import StoreCreate from './creates/StoreCreate';
import StoreEdit from './edits/StoreEdit';
import StoreList from './lists/StoreList';
import StoreShow from './shows/StoreShow';

import ProductCreate from './creates/ProductCreate';
import ProductEdit from './edits/ProductEdit';
import ProductList from './lists/ProductList';
import ProductShow from './shows/ProductShow';

import UserCreate from './creates/UserCreate';
import UserEdit from './edits/UserEdit';
import UserList from './lists/UserList';

import createHistory from 'history/createBrowserHistory';

// import DistributorList from './lists/DistributorList';

// // import createHistory from 'history';

const history = createHistory();

const App = () => {
    
    const [count, setCount] = React.useState(0)

    const refreshNotif =()=> {
        
        setTimeout(() => {
            dataProvider.getNewNotifications().then((result) => {
                console.log(result)
                if (result.data) {
                    setCount(result.data.amount)
                }
                refreshNotif()
            }).catch((err) => {
                console.error(err);
                refreshNotif()

            })
        }, 1000);
    }
    React.useEffect(() => {
        refreshNotif()
    }, [])

    return <Admin
    key={count}
    // dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}
    history={history}
    loginPage={MyLoginPage}
    theme={SinopadelTheme}
    customRoutes={[]}>

    {(permissions) => [

        permissions === 'admin' || permissions === 'super-admin' ? <Resource
            name={'clubs'}
            create={ClubCreate}
            list={ClubList}
            edit={ClubEdit}
            show={ClubShow}
            icon={ClubIcon}
        /> : null,

        permissions === 'admin' || permissions === 'super-admin' ? <Resource
            name={'event-drafts'}
            options={{ label: 'Events (drafts)' }}
            list={EventDraftList}
            edit={EventDraftEdit}
            show={EventDraftShow}
            icon={EventDraftIcon}
        /> : null,

        permissions === 'admin' || permissions === 'super-admin' ? <Resource
            name={'events'}
            // create={EventCreate}
            list={EventList}
            // edit={CustomTableEdit}
            show={EventShow}
            icon={EventIcon}
        /> : null,

        permissions === 'admin' || permissions === 'super-admin' ? <Resource
            name={'match-drafts'}
            options={{ label: 'Matches (drafts)' }}
            list={MatchDraftList}
            edit={MatchDraftEdit}
            show={MatchDraftShow}
            icon={MatchDraftIcon}
        /> : null,

        permissions === 'admin' || permissions === 'super-admin' ? <Resource
            name={'matches'}
            // create={MatchCreate}
            list={MatchList}
            // edit={MatchEdit}
            show={MatchShow}
            icon={MatchIcon}
        /> : null,

        permissions === 'admin' || permissions === 'super-admin' ? <Resource
            name={'cities'}
            create={CityCreate}
            list={CityList}
            edit={CityEdit}
            show={CityShow}
            icon={CityIcon}
        /> : null,

        permissions === 'admin' || permissions === 'super-admin' ? <Resource
            name={'players'}
            create={PlayerCreate}
            list={PlayerList}
            edit={PlayerEdit}
            show={PlayerShow}
            icon={PlayerIcon}
        /> : null,

        permissions === 'super-admin' ? <Resource
            name={'rankings'}
            list={RankingList}
            show={RankingShow}
            icon={RankingIcon}
        /> : null,


        permissions === 'admin' || permissions === 'super-admin' ? <Resource
            name={'categories'}
            create={CategoryCreate}
            list={CategoryList}
            edit={CategoryEdit}
            show={CategoryShow}
            icon={CategoryIcon}
        /> : null,

        permissions === 'admin' || permissions === 'super-admin' ? <Resource
            name={'products'}
            create={ProductCreate}
            list={ProductList}
            edit={ProductEdit}
            show={ProductShow}
            icon={ProductIcon}
        /> : null,

        permissions === 'admin' || permissions === 'super-admin' ? <Resource
            name={'stores'}
            create={StoreCreate}
            list={StoreList}
            edit={StoreEdit}
            show={StoreShow}
            icon={StoreIcon}
        /> : null,

        permissions === 'admin' || permissions === 'super-admin' ? <Resource
            key={count}
            name={'orders'}
            options={{
                // label: `Orders ${that.state.amount}`,
                // label: `Orders ${that.state && that.state.amount ? `(${that.state.amount})` : 'x'}`
                label: `Orders (${count})`
            }}
            // create={CustomTableCreate}
            list={OrderList}
            // edit={CustomTableEdit}
            show={OrderShow}
            icon={OrderIcon}
        /> : null,

        permissions === 'admin' || permissions === 'super-admin' ? <Resource
            name={'users'}
            create={UserCreate}
            list={UserList}
            edit={UserEdit}
            // show={CustomTableShow}
            icon={UserIcon}
        /> : null
    ]}

</Admin>
}

require('dotenv').config();


export default App;