import React from 'react';
import {
    TextField,
    FunctionField
} from 'react-admin';
import CustomTableList from './CustomTableList';
import ElipsizeTextField from '../fields/ElipsizeTextField';
import ImageFromAPIField from '../fields/ImageFromAPIField';

class ProductList extends CustomTableList {


    customHandled(fields, fieldName, fieldList) {
        if (fieldName === 'paymentMethod') {
            return true;
        } else if (fieldName === 'name') {
            fieldList.push(<TextField label="Name (english)" source="name.en"/>);
            fieldList.push(<TextField label="Name (中文)" source="name.zh"/>);
        } else if (fieldName === 'description') {
            fieldList.push(<ElipsizeTextField label="Description (english)" source="description" field="description.en" maxLength={50}/>);
            fieldList.push(<ElipsizeTextField label="Description (中文)" source="description" field="description.zh" maxLength={50}/>);
        } else if (fieldName === 'yuanPayment') {
            fieldList.push(<FunctionField label="Price 1" render={(product) => `${product.yuanPayment ? `${product.yuanPayment.yuan ? product.yuanPayment.yuan : '-'} ¥` : ''}` } />);
        } else if (fieldName === 'bothPayment') {
            fieldList.push(<FunctionField label="Price 2" render={(product) => `${product.bothPayment ? `${product.bothPayment.yuan ? product.bothPayment.yuan : '-'} ¥ + ${product.bothPayment.sployalty ? product.bothPayment.sployalty : '-'} SPL` : ''}` } />);
        } else if (fieldName === 'sployaltyPayment') {
            fieldList.push(<FunctionField label="Price 3" render={(product) => `${product.sployaltyPayment ? `${product.sployaltyPayment.sployalty ? product.sployaltyPayment.sployalty : '-'} SPL` : ''}` } />);
        } else if (fieldName === 'cover') {
            fieldList.push(<ImageFromAPIField label="Cover" source="cover" field="uri" folder="product-cover" height="250"/>);
            return true;
        }
        return false;
    }
}

export default ProductList;